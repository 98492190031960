import React from 'react';
import './App.css';
import { data, titleDta } from './data/data'
import Cards from './components/cards'

function App() {
  return (
    <div className="App">
      <div className="titles mb-4">
        <p className='  pt-5 fs-1 fw-light text-white ' >
          {titleDta.title}
        </p>
        <p className='text-muted m-0 mb-4 fs-3' >
          {titleDta.subtitle}
        </p>
      </div>
      <div className='d-flex flex-column pb-4
      overflow-auto justify-content-center align-items-center  '>
        {data?.map((item) => (
          <Cards key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
}

export default App;
