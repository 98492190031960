export const titleDta = {
    title: 'Forms to complete',
    subtitle: 'please choose the form you want to complete and click on the link'
}

export const data = [
    {
        id: 1,
        name: 'PMRO Travel Authorization Request (TAR) Form',
        description: 'PMRO Travel Authorization Request (TAR) Form descriprion',
        link: 'https://app.smartsheet.com/b/form/0daed64db5834519b85af0996f1da959',
        image: 'https://www.smartsheet.com/sites/default/files/styles/hero/public/hero-images/hero-image-1.jpg?itok=Z8ZQ8Z4Q',
        icon: 'https://picsum.photos/200/300',
    },
    {
        id: 2,
        name: 'Submit a RIP',
        description: 'Request to Initiate Purchase',
        link: 'https://app.smartsheet.com/b/form/45a1d7499f0a41acb0d27deac37a4b16',
        image: 'https://www.smartsheet.com/sites/default/files/styles/hero/public/hero-images/hero-image-1.jpg?itok=Z8ZQ8Z4Q',
        icon: 'https://picsum.photos/200/300',
    },
    {
        id: 3,
        name: 'Submit a Deliverable',
        description: 'PMRO Deliverable Submission',
        link: 'https://app.smartsheet.com/b/form/5a73d5622c43415799147849efe715d6',
        image: 'https://www.smartsheet.com/sites/default/files/styles/hero/public/hero-images/hero-image-1.jpg?itok=Z8ZQ8Z4Q',
        icon: 'https://picsum.photos/200/300',
    }
  
]