import React from 'react'
import { Row, Col } from 'reactstrap'


export default function Cards({ ...item }) {
    return (
        <Row className='m-3 p-3 shadow w-50   rounded  customCards h-25'
            onClick={() => window.open(item.link, '_blank')}>
            <Col md={2} className='m-0 p-0 '  >
                <img src={item.image} alt="" className='w-50 ' />
            </Col>
            <Col md={9} className='d-flex flex-column justify-content-center align-items-start'>
                <p className='fw-light fs-3 m-0 text-white' > {item.name}  </p>
                <p className='text-muted fs-5 text-white' >{item.description}</p>
            </Col>
        </Row>
    )
}
